import React, {
	useEffect,
	useState
} from 'react'


import { API_SERVER }   from 'config'
import Base           from 'components/Base'
import Editor         from './Editor'
import { parserType } from '../helpers'
import './style.css'


export default function Detail (props) {
	const [feedback, setFeedback] = useState([])

	useEffect(() => {
		fetch(`${API_SERVER}/feedback/${props.match.params.id}`)
			.then(response => response.json())
			.then(json => {
				setFeedback(json)
			})
	}, [])

	return (
		<Base>
			<header className="FeedbackDetail">
				<h2 className="FeedbackDetail-title">{ feedback.feedback_issue }</h2>
				<p className="FeedbackDetail-type">{ parserType(feedback.feedback_type) }</p>
			</header>
			{(!!feedback.feedback_descrption && !feedback.feedback_userEmail) && (
				<Editor feedback={feedback.feedback_descrption} />
			)}
			{(!!feedback.feedback_descrption && !!feedback.feedback_userEmail) && (
				<>
					<div className="FeedbackEditor">
						<pre>{feedback.feedback_descrption}</pre>
					</div>
					<img
						alt={feedback.feedback_issue}
						src={feedback.feedback_file}
						style={{
							width: '100%',
							maxWidth: '100%'
						}}
					/>
				</>
			)}

		</Base>
	)
}

