import schema from './schema'
import plugins from './plugins'

export { markActive } from './menu' 
export { default as schema } from './schema'
export { default as nodeViews } from './nodeViews'
export { default as pluginFilterTransaction } from './pluginFilterTransaction'

export const options = {
	plugins,
	schema
}

export { default as menu } from './menu'
