import React, { PureComponent } from 'react'
import io from 'socket.io-client'

import Base from 'components/Base'
import MaterialIcon from '@material/react-material-icon'
import TextField, { Input } from '@material/react-text-field'
import Button from '@material/react-button'
import Billing from '../Billing'

import Table from './Table'
import { API_SERVER, WS_SERVER } from 'config'
import './table.css'


export function formatDate(data, includeHour = true) {
	let date = new Date(data)
	
	if (date) {
		let day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
		let monthNum = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1

		let hours = date.getHours()
		let minutes = date.getMinutes()

		hours = hours < 10 ? `0${hours}` : hours
		minutes = minutes < 10 ? `0${minutes}` : minutes

		let hour = !!includeHour ? `${hours}:${minutes}` : ''

		return `${day}/${monthNum}/${date.getFullYear()} ${hour}`
	}
	else return ''
}

class Portfolio extends PureComponent {

	state = {
		search: '',
		portfolio: [],
		isLoading: true,
		version: {}
	}

	async componentDidMount () {
		this.isMount = true

		console.log(WS_SERVER);
		this.io = io.connect(WS_SERVER)
		console.log(WS_SERVER);
		console.log(this.io);
		const res = await fetch(`${API_SERVER}/portfolio`)
		const data = await res.json()
	
		this.setState({ portfolio: data, isLoading: false })

		this.io.on('update::version::app', this.handleNewVersion)
		this.onFetchVersion()
	}

	onFetchVersion = async () => {
		const res = await fetch(`${API_SERVER}/version-app`)
		const version = await res.json()

		this.setState({ version })
	}

	handleNewVersion = version => this.setState({ version })

	setField = event => {
		const { name, value } = event.currentTarget
		this.setState({ [name]: value })
	}

	handleUpdate = () => {
		console.log(this.io.emit);
		this.io.emit('update::version::app')
	}

	render () {
		const { version } = this.state
		return (
			<Base>
				<br/>
				<Button outlined onClick={this.handleUpdate}>Actualizar app</Button>
				<ul>
					<li>Última actualización</li>
					<li>VERSION:&nbsp;{ version.version }</li>
					<li>FECHA:&nbsp;{ formatDate(new Date(version.date)) }</li>
				</ul>

				<br />
				<Billing />
				<br />

				<div className="SearchTable">
					<TextField
						dense
						label='Buscar:'
						trailingIcon={<MaterialIcon role="button" icon="search" />}
					>
						<Input
							name="search"
							value={this.state.search}
							onChange={this.setField}
						/>
					</TextField>
				</div>
				<Table
					portfolio={this.state.portfolio}
					search={this.state.search}
					isLoading={this.state.isLoading}
				/>
			</Base>
		)
	}

}

export default Portfolio
