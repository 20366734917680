const OPTIONS_LIST = [
	{ name: 'Aprender 4doing', code: 'Learn 4doing', },
	{ name: '¿Qué hay de nuevo?', code: 'Whats new?', },
	{ name: 'Preguntas frecuentes', code: 'Frequent questions', },

	{ name: 'Solicitar nueva característica', code: 'RequestFeature', isClick: true },
	{ name: 'Reportar un error', code: 'ReportBug', isClick: true },
	{ name: 'Comunícate con nosotros', code: 'ContactUs', isClick: true },
]

export function parserType (type) {
	const find = OPTIONS_LIST.find(opt => opt.code === type) || {}

	return find.name
}
