import React from 'react'

export const MONTHS = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

export function formatDate(data) {
	let date = new Date(data)

	if (Date.parse(date)) {
		let day = date.getDate().toString().padStart(2, 0)
		return `${MONTHS[date.getMonth()]} ${day}, ${date.getFullYear()}`
	}

	return null
}

function formatMethodPay (methodPay) {
	if (methodPay === 'wireTransfer') return 'Transferencia'
	if (methodPay === 'creditCard') return 'Tarjeta de crédito'
}


export default function Item ({ item, plan }) {
	const start = formatDate(item?.billing_start)
	const end = formatDate(item?.billing_end)

	return (
		<tr>
			<td>{item?.id_portfolio?.portf_company}</td>
			<td>{item?.id_portfolio?.portf_domain}</td>

			<td>{plan.name}</td>

			<td>
				{start} - {end}
			</td>

			<td>{formatMethodPay(item?.billing_methodPay)}</td>
			<td>{item?.billing_countUsers}</td>
			<td>{item?.billing_total}</td>
			<td>
				
			</td>
		</tr>
	)
}
