import React, { PureComponent } from 'react'


class Item extends PureComponent {
  render () {
    const { item } = this.props

		if (!item) {
			return null
		}

    return (
      <tr>
        <td>{ item?.portf_company }</td>
        <td>{ item?.portf_domain }</td>
        <td>{ item?.type }</td>
        <td>{ item?.count }</td>
      </tr>
    )
  }
}





export default Item
