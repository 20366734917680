import { Plugin }    from 'prosemirror-state'
import { Transform } from 'prosemirror-transform'


function onSelectEditor(transition, state, actions) {
	const pos = transition.steps[0]
	const newDoc = state.doc.slice(pos.from, pos.to).toJSON()

	if (!newDoc) return false

	const maps = newDoc.content
		.map(item => {
			if (item.type === 'project') return item
			if (item.type === 'image') return item
			else if (item.type === 'paragraph' && Array.isArray(item.content)) {
				const select = item.content[0]

				if (select.type === 'image') return select
				else return null
			}
			else return null
		})
		.filter(d => !!d)

	const projects = maps
		.map(item => {
			if (item.type === 'project') return item.attrs
			else return null
		})
		.filter(i => !!i)

	const images = maps
		.map(item => {
			if (item.type === 'image') return item.attrs
			else return null
		})
		.filter(i => !!i)

	actions.onDeleteMultipleProject(projects)
	actions.onDeleteMultipleImages(images)
}

function onDndProjects (transition, state, actions) {
	const tr = new Transform(state.doc)

	transition.steps.forEach(step => {
		tr.replace(step.from, step.to, step.slice)
	})

	const newDoc = tr.doc.toJSON()

	if (!newDoc) return false

	const maps = newDoc.content
		.map(item => {
			if (item.type === 'project') return item
			else return null
		})
		.filter(d => !!d)

	const projects = maps
		.map(item => {
			if (item.type === 'project') return item.attrs
			else return null
		})
		.filter(i => !!i)


	actions.onDndProjects(projects)
}

function plugin (actions) {
	return new Plugin({
		key: 'blockDeletedPI',
		filterTransaction: (tr, state) => {
			/*
			refactor ejecutar solo cuando se activan proyectos
			"ahora se ejecutan en todos"
			*/
			if (tr.meta.uiEvent === 'drop') {
				onDndProjects(tr, state, actions)
			}

			for (const item of tr.mapping.maps) {
				const ranges = item.ranges
	
				if (!!ranges.length && !(tr.meta.uiEvent === 'drop' || !!tr.meta.collab$) ) {
					onSelectEditor(tr, state, actions)
				}
			}

			return true
		}
	})

}

export default plugin
