import React from 'react'
import Button from '@material/react-button'


function Item(props) {
	function handleDelete () {
		props.onDelete('template', props.item)
	}

	return (
		<article className="Templates-Item">
			<div className="Templates-Item--emoji">
				{props.item.template_project.project_emoji}
			</div>
			<div className="Templates-Item--name">
				<div>
					{props.item.template_project.project_name}
				</div>
				<div className="Templates-Item--category">
					{props.category.category_name || 'categoria'}
				</div>
			</div>
			<Button raised onClick={handleDelete}>Eliminar</Button>
		</article>
	)
}

export default Item
