import { toggleMark } from 'prosemirror-commands'
import { redo, undo } from 'prosemirror-history'

import schema from './schema'
import toggleList from '../commands/toggleList'
import toggleWrap from '../commands/toggleWrap'
import nodeIsActive from '../commands/nodeIsActive'
import toggleBlockType from '../commands/toggleBlockType'

export const markActive = type => state => {
	const { from, $from, to, empty } = state.selection

	return empty
		? type.isInSet(state.storedMarks || $from.marks())
		: state.doc.rangeHasMark(from, to, type)
}

const promptForURL = () => {
	let url = window && window.prompt('Enter the URL', 'https://')

	if (url && !/^https?:\/\//i.test(url)) url = 'http://' + url

	return url
}

export default {
	marks: {
		em: {
			run: toggleMark(schema.marks.em),
			active: markActive(schema.marks.em),
		},
		strong: {
			run: toggleMark(schema.marks.strong),
			active: markActive(schema.marks.strong),
		},
		subscript: {
			run: toggleMark(schema.marks.subscript),
			active: markActive(schema.marks.subscript),
		},
		superscript: {
			run: toggleMark(schema.marks.superscript),
			active: markActive(schema.marks.superscript),
		},
		underline: {
			run: toggleMark(schema.marks.underline),
			active: markActive(schema.marks.underline),
		},
		strikethrough: {
			run: toggleMark(schema.marks.strikethrough),
			active: markActive(schema.marks.strikethrough),
		},
		link: {
			run(state, dispatch) {
				if (markActive(schema.marks.link)(state)) {
					toggleMark(schema.marks.link)(state, dispatch)
					return true
				}

				const href = promptForURL()
				if (!href) return false

				toggleMark(schema.marks.link, { href })(state, dispatch)
			}
		}
	},

	blocks: {

		h1: {
			run: toggleBlockType(schema.nodes.heading,  schema.nodes.paragraph, { level: 1 }),
			active: nodeIsActive(schema.nodes.heading, { level: 1 }),
		},
		h2: {
			run: toggleBlockType(schema.nodes.heading,  schema.nodes.paragraph, { level: 2 }),
			active: nodeIsActive(schema.nodes.heading, { level: 2 }),
		},
		h3: {
			run: toggleBlockType(schema.nodes.heading, schema.nodes.paragraph, { level: 3}),
			active: nodeIsActive(schema.nodes.heading, { level: 3 }),
		},

		plain: {
			run: toggleBlockType(schema.nodes.paragraph, schema.nodes.paragraph),
			active: nodeIsActive(schema.nodes.paragraph),
		},
		code_block: {
			run: toggleBlockType(schema.nodes.code_block, schema.nodes.paragraph),
			active: nodeIsActive(schema.nodes.code_block),
		},
		blockquote: {
			run: toggleWrap(schema.nodes.blockquote, schema.nodes.paragraph),
			active: nodeIsActive(schema.nodes.blockquote),
		},

		todo_list: {
			run: toggleList(schema.nodes.todo_list, schema.nodes.todo_item),
			active: nodeIsActive(schema.nodes.todo_item),
		},
		bullet_list: {
			run: toggleList(schema.nodes.bullet_list, schema.nodes.list_item),
			active: nodeIsActive(schema.nodes.bullet_list),
		},
		ordered_list: {
			run: toggleList(schema.nodes.ordered_list, schema.nodes.list_item),
			active: nodeIsActive(schema.nodes.ordered_list),
		}
	},

	history: {
		undo: {
			enable: undo,
			run: undo
		},
		redo: {
			enable: redo,
			run: redo
		}
	}
}
