import ReactDOM from 'react-dom'
import React from 'react'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import Loader from '../Component/Loader'


const placeholderPlugin = new Plugin({
	state: {
		init() {
			return DecorationSet.empty
		},
		apply(tr, set) {
			set = set.map(tr.mapping, tr.doc)
			const action = tr.getMeta(this)

			if (action && action.add) {
				const widget = document.createElement('placeholder')
				ReactDOM.render(<Loader />, widget)

				const deco = Decoration.widget(action.add.pos, widget, { id: action.add.id })

				set = set.add(tr.doc, [deco])
			}
			else if (action && action.remove) {
				set = set.remove(set.find(null, null, spec => spec.id === action.remove.id))
			}

			return set
		}
	},
	props: {
		decorations(state) {
			return this.getState(state)
		}
	}
})


export function findPlaceholder(state, id) {
	const decos = placeholderPlugin.getState(state)
	const found = decos.find(null, null, spec => spec.id === id)

	return found.length ? found[0].from : null
}

export default placeholderPlugin
