import React, {
	useEffect,
	useState
} from 'react'
import { API_SERVER } from 'config'
import Base         from 'components/Base'
import Item         from './Item'

export default function Feedback (props) {
	const [feedbacks, setFeedbacks] = useState([])

	useEffect(() => {
		fetch(`${API_SERVER}/feedback`)
			.then(response => response.json())
			.then(json => {
				setFeedbacks(json)
			})
	}, [])

	return (
		<Base>
			<section className="table--container">
				<table>
					<thead>
						<tr>
							<th>Asunto</th>
							<th>Tipo</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{feedbacks.map(item => (
							<Item
								key={item._id}
								item={item}
							/>
						))}
					</tbody>
				</table>
			</section>
		</Base>
	)
}
