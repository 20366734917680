import React, { PureComponent } from 'react'
import List, { ListItem, ListItemText } from '@material/react-list'
import { Cell, Grid, Row } from '@material/react-layout-grid'


import '@material/react-list/dist/list.css'
import '@material/react-layout-grid/dist/layout-grid.css'


class Plans extends PureComponent {

	getInterval (interval) {
		let type = 'mes'

		if (interval === 'day') type = 'dia'
		if (interval === 'week') type = 'semana'
		if (interval === 'year') type = 'año'

		return type
	}

	render () {
		return (
			<List twoLine>
				<Grid>
					<Row>
						{
							this.props.plans.map(item => (
								<Cell key={item._id}>
									<ListItem columns={6}>
										<ListItemText
											primaryText={ item.plan_name }
											secondaryText={` $${parseFloat(item.plan_amount / 100).toFixed(2)}, ${ this.getInterval(item.plan_interval) } `} />
									</ListItem>
								</Cell>
							))
						}
					</Row>
				</Grid>
			</List>
		)
	}
}

export default Plans
