import React, { PureComponent } from 'react'
import LinearProgress from '@material/react-linear-progress'
import Item from './Item'



class Table extends PureComponent {

  renderItem (item, index) {
    return <Item key={index} item={item} />
  }

  render () {
    const renderTodos = this.props.portfolio.map((item, index) => {
			let name = `${item.portf_company } ${item.portf_domain}`

      if (name.toLowerCase().search(this.props.search.toLocaleLowerCase()) > -1) {
        return this.renderItem(item, index)
			}
			return null
    })

    return (
      <section className="table--container">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Url</th>
              <th>Tipo</th>
              <th># Usuarios</th>
            </tr>
          </thead>
          <tbody>
            { this.props.isLoading && (
              <tr>
                <td colSpan="4">
                  <LinearProgress indeterminate buffer={0.9} progress={0.8} />
                </td>
              </tr>
            ) }
            { (!this.props.isLoading && !this.props.portfolio.length) && (
              <tr>
                <td colSpan="7">No hay portafolios</td>
              </tr>
            )}
            { renderTodos }
          </tbody>
        </table>
      </section>
    )
  }
}




export default Table
