import React, { useState } from 'react'
import TextField, { Input } from '@material/react-text-field'
import MaterialIcon from '@material/react-material-icon'
import Button from '@material/react-button'



function FormCategory (props) {
	const [name, setName] = useState('')

	function handleSaved () {
		const payload = {
			category_name: name,
			category_sort: props.categoryCount + 1
		}

		props.onSaved(payload)
		setName('')
	}

	return (
		<section className="Templates-Form">
			<h2 className="Templates-title">Categorias</h2>

			<TextField
				label='Nombre'
				trailingIcon={<MaterialIcon role="button" icon="category" />}
			>
				<Input
					value={name}
					onChange={({ target: { value } }) => setName(value)}
				/>
			</TextField>

			<Button
				raised
				className='button-alternate'
				onClick={handleSaved}
			>
				Guardar
			</Button>
		</section>
	)
}

export default FormCategory
