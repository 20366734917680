import { DecorationSet, Decoration } from 'prosemirror-view'
import { Plugin } from 'prosemirror-state'


export default () => {
	return new Plugin({
		props: {
			decorations: state => {
				const doc = state.doc
				const decorations = []

				if (doc.childCount <= 1 && doc.firstChild.isTextblock && doc.firstChild.content.size <= 0 ) {

					const decorate = (node, pos) => {
						if (node.type.isBlock && node.childCount === 0) {
							decorations.push(Decoration.node(pos, pos + node.nodeSize, { class: 'empty-node' }))
						}
					}

					state.doc.descendants(decorate)
					return DecorationSet.create(state.doc, decorations)
				}

			}
		}
	})
}
