import React, { PureComponent } from 'react'
import socket from 'socket.io-client'
import Base from 'components/Base'
import FormCategory from './FormCategory'
import FormTemplate from './FormTemplate'
import ListTemplate from './ListTemplate'
import ListCategory from './ListCategory'

import { WS_SERVER, API_SERVER } from 'config'
import './style.css'



class Templates extends PureComponent {

	state = {
		categoryFilter: '',
		templates: [],
		categories: []
	}


	async componentDidMount() {
		this.isMount = true
		this.io = socket(WS_SERVER, { transports: ['websocket'] })

		this.io.on('created::templateProject', this.handleAddTemplate)
		this.io.on('deleted::templateProject', this.handleDeleteTemplate)

		this.io.on('created::categoryTemplate', this.handleAddCategory)
		this.io.on('deleted::categoryTemplate', this.handleDeleteCategory)
		this.io.on('dnd::categoryTemplate', this.handleDndCategory)


		const templateFetch = await fetch(`${API_SERVER}/templates-project`)
		const templates = await templateFetch.json()

		const categoriesFetch = await fetch(`${API_SERVER}/templates-category`)
		const categories = await categoriesFetch.json()

		this.setState({ templates, categories })
	}

	setCategory = value => this.setState({ categoryFilter: value })

	handleAddTemplate = data => {
		this.setState(state => ({
			templates: state.templates.concat(data)
		}))
	}
	handleDeleteTemplate = data => {
		this.setState(state => ({
			templates: state.templates.filter(t => t._id !== data)
		}))
	}

	handleAddCategory = data => {
		this.setState(state => ({
			categories: state.categories.concat(data)
		}))
	}
	handleDeleteCategory = data => {
		this.setState(state => ({
			categories: state.categories.filter(t => t._id !== data)
		}))
	}

	handleDndCategory = data => {
		this.setState({ categories: data })
	}

	handleDndCategories = payload => {
		this.setState({ categories: payload })
		this.io.emit('dnd::categoryTemplate', payload)
	}

	handleDelete = (type, action) => {
		const event = type === 'category'
			? 'deleted::categoryTemplate'
			: 'deleted::templateProject'

		this.io.emit(event, action._id)
	}

	handleSaved = payload => {
		this.io.emit('created::templateProject', payload)
	}

	handleSavedCategory = payload => {
		this.io.emit('created::categoryTemplate', payload)
	}

	render () {
		const templatesFilter = this.state.templates.filter(c => c.template_category === this.state.categoryFilter).length

		return (
			<Base>
				<section className="TemplatesLayout">
					<section className="CategoryTemplates">
						<FormCategory
							categoryCount={this.state.categories.length}
							onSaved={this.handleSavedCategory}
						/>
						<ListCategory
							onDndCategories={this.handleDndCategories}
							categories={this.state.categories}
							onDelete={this.handleDelete}
						/>
					</section>

					<section className="Templates">
						<FormTemplate
							onSaved={this.handleSaved}
							setCategory={this.setCategory}
							categories={this.state.categories}
							templatesFilter={templatesFilter}
						/>
						<ListTemplate
							categoryFilter={this.state.categoryFilter}
							categories={this.state.categories}
							templates={this.state.templates}
							onDelete={this.handleDelete}
						/>
					</section>
				</section>

			</Base>
		)
	}
}

export default Templates
