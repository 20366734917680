import React, {
	useEffect,
	useRef
} from 'react'
import {
	EditorState,
} from 'prosemirror-state'
import { EditorView }      from 'prosemirror-view'
import { options }         from './schema'


export default function Editor (props) {
	const editorRef = useRef(null)

	useEffect(() => {
		const { schema } = options
		const parse = JSON.parse(props.feedback)
		options.doc = schema.nodeFromJSON(parse)

		const view = new EditorView(null, {
			state: EditorState.create({
				...options,
			}),
			editable: () => false,
		})

		editorRef.current.appendChild(view.dom)
	}, [])

	return (
		<div ref={editorRef} className="FeedbackEditor">
		</div>
	)
}

