import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import ItemCategory from './ItemCategory'


const getListStyle = isDraggingOver => {
	let style = {}
	if (isDraggingOver) style = { ...style, background: '#f3f3f3', }

	return style
}

const reorder = (array, startIndex, endIndex) => {
	const result = Array.from(array)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}


function List (props) {

	function handleDragEnd (result) {
		const { destination, source } = result

		if (!destination) return

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) return


		const arrayReorder = reorder(
			props.categories,
			source.index,
			destination.index,
		)

		const payload = arrayReorder.map((item, index) => ({
			...item,
			category_sort: index + 1
		}))

		props.onDndCategories(payload)
	}

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable
				droppableId="list"
				direction="vertical"
				type="CATEGORIES_TEMPLATE"
			>
				{(provided, snapshot) => (
				<section
					ref={provided.innerRef}
					className="Templates-List"
					{...provided.droppableProps}
					style={getListStyle(snapshot.isDraggingOver)}
				>
					{props.categories.map((item, index) => (
						<ItemCategory
							index={index}
							item={item}
							key={item._id}
							onDelete={props.onDelete}
						/>
					))}
				</section>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export default List
