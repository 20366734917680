import React          from 'react'
import { parserType } from './helpers'
import Button         from '@material/react-button'
import { Link }       from 'react-router-dom'


export default function Item ({ item }) {
	return (
		<tr>
			<td>{item.feedback_issue}</td>
			<td>{ parserType(item.feedback_type) }</td>
			<td>
				<Link to={`/feedback/${item._id}`}>
					<Button raised>Abrir</Button>
				</Link>
			</td>
		</tr>
	)
}
