import React, { useState } from 'react'
import TextField, { Input } from '@material/react-text-field'
import MaterialIcon from '@material/react-material-icon'
import Select from '@material/react-select'
import Button from '@material/react-button'


function FormTemplate(props) {
	const [category, setCategory] = useState('')
	const [url, setUrl] = useState('')

	function handleSaved () {
		const projectId = url.split('-')[0]
		const payload = {
			template_project: projectId,
			template_category: category,
			template_sort: props.templatesFilter + 1
		}

		props.onSaved(payload)

		setCategory('')
		setUrl('')
		props.setCategory('')
	}

	function handleCategory ({ target: { value } }) {
		setCategory(value)
		props.setCategory(value)
	}

	const options = props.categories.map(c => ({ label: c.category_name, value: c._id }))

	return (
		<section className="Templates-Form">
			<h2 className="Templates-title">Plantillas</h2>

			<div className="Templates-Form--templates">
				<Select
					label='Categoria'
					name="category"
					value={category}
					onChange={handleCategory}
					options={[{ label: '', value: '' }, ...options]}
				/>

				<TextField
					label='URL'
					trailingIcon={<MaterialIcon role="button" icon="assignment" />}
				>
					<Input
						value={url}
						onChange={({ target: { value } }) => setUrl(value)}
					/>
				</TextField>
			</div>

			<Button
				raised
				className='button-alternate'
				onClick={handleSaved}
			>
				Guardar
			</Button>
		</section>
	)
}

export default FormTemplate
