import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import Button from '@material/react-button'


const getItemStyle = (isDragging, draggableStyle) => {
	let object = {
		userSelect: 'none',
		outline: 'none',
		...draggableStyle,
	}

	if (isDragging) {
		object = {
			...object,
			background: '#e8e8e8',
			opacity: '0.5'
		}
	}

	return object
}



function Item(props) {

	function handleDelete () {
		props.onDelete('category', props.item)
	}

	return (
		<Draggable
			index={props.index}
			draggableId={props.item._id}
		>
			{(provided, snapshot) => (
				<div>
					<article
						{...provided.dragHandleProps}
						{...provided.draggableProps}
						ref={provided.innerRef}
						style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
						className="Templates-Item"
					>
						<div className="Templates-Item--name">
							<div>{props.item.category_name}</div>
						</div>
						<Button raised onClick={handleDelete}>Eliminar</Button>
					</article>
					{provided.placeholder}
				</div>
			)}
		</Draggable>
	)
}

export default Item
