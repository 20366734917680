import React from 'react'
import ItemTemplate from './ItemTemplate'


function List (props) {

	function findCategory (item) {
		const find = props.categories.find(c => item.template_category === c._id)

		return find || {}
	}

	const templates = !!props.categoryFilter
		? props.templates.filter(c => c.template_category === props.categoryFilter)
		: props.templates

	return (
		<section className="Templates-List">
			{templates.map(item => (
				<ItemTemplate
					item={item}
					key={item._id}
					onDelete={props.onDelete}
					category={findCategory(item)}
				/>
			))}
		</section>
	)
}

export default List
