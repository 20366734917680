import React, { PureComponent } from 'react'
import { Link }                 from 'react-router-dom'
import { MAIN }                 from 'config'

import TopAppBar, {
	TopAppBarIcon,
	TopAppBarRow,
	TopAppBarSection,
	TopAppBarTitle,
} from '@material/react-top-app-bar';
import MaterialIcon             from '@material/react-material-icon'

import '@material/react-top-app-bar/dist/top-app-bar.css'
import './style.css'

class Header extends PureComponent {

	render() {
		console.log(MAIN)
		return (
			<TopAppBar>
				<TopAppBarRow>
					<TopAppBarSection align='start'>
						<TopAppBarIcon navIcon tabIndex={0}>
							<MaterialIcon hasRipple icon='menu' onClick={() => console.log('click')} />
						</TopAppBarIcon>
						<TopAppBarTitle>4 Doing</TopAppBarTitle>
					</TopAppBarSection>

					<TopAppBarSection align='end' role='toolbar'>
						<Link to="/" className="Toolbar-list--item">
							<TopAppBarIcon actionItem tabIndex={0}>
								<MaterialIcon key='item' icon='work_outline' />
							</TopAppBarIcon>
						</Link>

						<Link to="/codigo-acceso" className="Toolbar-list--item">
							<TopAppBarIcon actionItem tabIndex={0}>
								<MaterialIcon key='item' icon='credit_card' />
							</TopAppBarIcon>
						</Link>

						{/*
						<Link to="/planes" className="Toolbar-list--item">
							<TopAppBarIcon actionItem tabIndex={0}>
								<MaterialIcon key='item' icon='credit_card' />
            	</TopAppBarIcon>
						</Link>
						*/}

						<Link to="/plantillas" className="Toolbar-list--item">
							<TopAppBarIcon actionItem tabIndex={0}>
								<MaterialIcon key='item' icon='assignment' />
							</TopAppBarIcon>
						</Link>

						<Link to="/feedback" className="Toolbar-list--item">
							<TopAppBarIcon actionItem tabIndex={0}>
								<MaterialIcon key='item' icon='forum' />
							</TopAppBarIcon>
						</Link>

						<a href={`${MAIN}`} className="Toolbar-list--item">
							<TopAppBarIcon actionItem tabIndex={0}>
								<MaterialIcon key='item' icon='exit_to_app' />
							</TopAppBarIcon>
						</a>
					</TopAppBarSection>

				</TopAppBarRow>
			</TopAppBar>
		)
	}
}

export default Header
