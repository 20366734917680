import React, { useEffect, useState } from 'react'
import { API_SERVER } from 'config'
import Base from 'components/Base'
import Item from './Item'
import Billing from '../Billing'
import Button from '@material/react-button'
import './style.css'


function PlanCodeAccess (props) {
	const [codeAccess, setCodeAccess] = useState([])
	const [plans, setPlans] = useState([])

	useEffect(() => {
		fetch(`${API_SERVER}/plan-code-access`)
			.then(response => response.json())
			.then(json => {
				setCodeAccess(json)
			})

		fetch(`${API_SERVER}/plans`)
			.then(response => response.json())
			.then(json => {
				setPlans(json.plans)
			})
	}, [])

	const handleGenerate = () => {
		fetch(`${API_SERVER}/plan-code-access`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		})
		.then(response => response.json())
		.then(json => {
			window.location.reload()
		})
	}

	return (
		<Base>
			<br />
			<Button outlined onClick={handleGenerate}>Generar codigo de acceso</Button>
			<br />
			<section className="PlansAccessCode">
				{codeAccess.map(item => (
					<Item 
						key={item._id}
						item={item}
						plan={plans.find(p => p.plan === item.codeAccess_plan) || {}}
					/>
				))}
			</section>

			<br />

			<Billing />
			<br />
			<br />

		</Base>
	)
}

export default PlanCodeAccess
