import React, { Component } from 'react'
import {TopAppBarFixedAdjust} from '@material/react-top-app-bar'

import Header from 'components/Header'


import '@material/react-text-field/dist/text-field.css'
import '@material/react-button/dist/button.min.css'
import '@material/react-material-icon/dist/material-icon.css'
import '@material/react-select/dist/select.css'
import '@material/react-linear-progress/dist/linear-progress.css';
import './style.css'


class App extends Component {

	render() {

		return (
			<main>
				<Header />
				<TopAppBarFixedAdjust>
					<section className="Container">
						{ this.props.children }
					</section>
	      </TopAppBarFixedAdjust>
			</main>
		)
	}
}

export default App;
