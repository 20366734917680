import React, { useState, useEffect } from 'react'
import { API_SERVER } from 'config'
import Item from './Item'


function Billing (props) {
	const [billing, setBilling] = useState([])
	const [plans, setPlans] = useState([])

	useEffect(() => {
		fetch(`${API_SERVER}/billing`)
			.then(response => response.json())
			.then(json => {
				setBilling(json)
			})

		fetch(`${API_SERVER}/plans`)
			.then(response => response.json())
			.then(json => {
				setPlans(json.plans)
			})

	}, [])

	return (
		<section className="table--container">
			<table>
				<thead>
					<tr>
						<th>Espacio de trabajo</th>
						<th>Url</th>
						<th>Plan</th>
						<th>Fechas</th>
						<th>Método de pago</th>
						<th># usuarios</th>
						<th>$ total</th>
						<th>auto renovacíon</th>
					</tr>
				</thead>
				<tbody>
					{billing.map(item => (
						<Item
							key={item._id}
							item={item}
							plan={plans.find(p => p.plan === item.billing_plan) || {}}
						/>
					))}
				</tbody>
			</table>
		</section>
	)
}

export default Billing
