import { nodes } from 'prosemirror-schema-basic'
import { orderedList, bulletList, listItem } from 'prosemirror-schema-list'
import { tableNodes } from 'prosemirror-tables'


const listNodes = {
	ordered_list: {
		...orderedList,
		content: 'list_item+',
		group: 'block'
	},
	bullet_list: {
		...bulletList,
		content: 'list_item+',
		group: 'block'
	},
	list_item: {
		...listItem,
		content: 'paragraph block*'
	}
}

const nodesEditor = {
	...nodes,
	...listNodes,
	...tableNodes({
		tableGroup: 'block',
		cellContent: 'block+'
	}),
	todo_list: {
		draggable: false,
		group: 'block',
		content: 'todo_item+',
		toDOM: () => ['ul', { 'data-type': 'todo_list', class: "EditorTodo" }, 0],
		parseDOM: [{
			priority: 51,
			tag: `[data-type="todo_list"]`,
		}],
	},
	todo_item: {
		draggable: true,
		attrs: {
			done: { default: false },
		},
		content: 'paragraph+',
		toDOM: node => {
			const { done } = node.attrs

			return [
				'li',
				{
					class: "EditorTodo-item",
					'data-type': 'todo_item',
					'data-done': done.toString(),
				},
				['span', { class: 'EditorTodo-checkbox', contenteditable: 'false' }],
				['div', { class: 'EditorTodo-content' }, 0],
			]
		},
		parseDOM: [{
			priority: 51,
			tag: `[data-type="todo_item"]`,
			getAttrs: dom => ({
				done: dom.getAttribute('data-done') === 'true',
			}),
		}],
	},
	image: {
		draggable: true,
		group: 'block',
		attrs: {
			...nodes.image.attrs,
		},
		inclusive: false,
		contenteditable: false,
		toDOM: (node) => {
			return ['img', { ...node.attrs, contenteditable: false }, '' ]
		}

	},
	media: {
		draggable: true,
		group: 'block',
		attrs: {
			uri: { default: '' },
			name: { default: '' },
		},
		inclusive: false,
		contenteditable: false,
		toDOM: node => {
			return ['div', { 'data-type': 'media', ...node.attrs, contenteditable: false }, '']
		}
	},
	project: {
		draggable: true,
		group: 'block',
		attrs: { id: '' },
		inclusive: false,
		contenteditable: false,
		toDOM: node => {
			return ['div', { 'data-type': 'project', ...node.attrs, contenteditable: false }, '']
		}
	}
}

export default nodesEditor
