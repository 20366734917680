import {
	findParentNode,
	findSelectedNodeOfType,
} from 'prosemirror-utils'

const nodeIsActive = (type, attrs = {}) => state => {
	const predicate = node => node.type === type
	const node = findSelectedNodeOfType(type)(state.selection)
		|| findParentNode(predicate)(state.selection)

	if (!Object.keys(attrs).length || !node) {
		return !!node
	}

	return node.node.hasMarkup(type, { ...node.node.attrs, ...attrs })
}

export default nodeIsActive
