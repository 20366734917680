import React, { Component } from 'react'
import socket from 'socket.io-client'
import Base from 'components/Base'

import TextField, { Input } from '@material/react-text-field'
import MaterialIcon from '@material/react-material-icon'
import Button from '@material/react-button'
import Select from '@material/react-select'

import PlansList from './PlansList'

import { WS_SERVER, API_SERVER } from 'config'

const options = [
	{
		label: 'Dia',
		value: 'day',
	},
	{
		label: 'Semana',
		value: 'week',
	},
	{
		label: 'Mes',
		value: 'month',
	},
	{
		label: 'Año',
		value: 'year',
	}
]

const initalState = {
	name: '',
	amount: '',
	interval: 'month',
	plans: []
}

class App extends Component {

	constructor (props) {
		super(props)

		this.state = initalState
		this.isMount = false

		this.setField = this.setField.bind(this)
		this.handleSaved = this.handleSaved.bind(this)
		this.addPlan = this.addPlan.bind(this)
	}

	async componentDidMount () {
		this.isMount = true
		this.io = socket(WS_SERVER, { transports: ['websocket'] })

		this.io.on('create::plan', this.addPlan)

		const res = await fetch(`${API_SERVER}/plans`)
		const data = await res.json()
	
		this.setState({ plans: data })
	}

	componentWillUnmount () {
		if (!this.isMount) return false

		this.io.off('create::plan', this.addPlan)
		this.io.close()
	}

	addPlan (plan) {
		this.setState(state => ({
			plans: [...state.plans, plan]
		}))
	}

	setField (event) {
		const { name, value } = event.target
		this.setState({ [name]: value })
	}

	handleSaved () {
		const object = {
			name: this.state.name,
			interval: this.state.interval,
			amount: parseFloat(this.state.amount) * 100
		}
		
		this.io.emit("create::plan", object)
		this.setState({
			name: '',
			interval: '',
			amount: '',
		})
	}

	render() {

		return (
			<Base>
				<PlansList plans={this.state.plans} />
				<article style={{ display: 'grid', width: '30%', margin: '0 auto', gridGap: '10px 0' }}>
					<TextField
						label='Nombre del plan'
						trailingIcon={<MaterialIcon role="button" icon="credit_card" />}
					>
						<Input
							value={this.state.name}
							onChange={this.setField}
							name="name"
						/>
					</TextField>
					<TextField
						label='Cantidad del plan'
						trailingIcon={<MaterialIcon role="button" icon="attach_money" />}
					>
						<Input
							value={this.state.amount}
							onChange={this.setField}
							name="amount"
						/>
					</TextField>
					<Select
						label='Tiempo de cobro'
						onChange={this.setField}
						name="interval"
						value={this.state.interval}
						options={options}
					/>
					<Button
						raised
						className='button-alternate'
						onClick={this.handleSaved}
					>
						Guardar
					</Button>
				</article>
			</Base>
		)
	}
}

export default App;
