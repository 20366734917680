import React from 'react'
import Button from '@material/react-button'

const INTERVALS = [
	{ code: 'annually', name: 'Anual' },
	{ code: 'monthly', name: 'Mensual' },
]


function Item (props) {

	const interval = INTERVALS.find(i => i.code === props.item.codeAccess_interval) || {}

	const handleCopy = () => {
		const aux = document.createElement("input")
		aux.setAttribute('value', props.item.codeAccess_code)

		document.body.appendChild(aux);
		aux.select();
		document.execCommand('copy');
		document.body.removeChild(aux);
	}

	return (
		<article className="Templates-Item">
			<div style={{ width: '100%' }}>
				<div className="Templates-Item--name">
					<p>{props.item.codeAccess_code}</p>
				</div>
				<div className="Templates-Item--category">
					{props.plan.name} - {interval.name}
				</div>
			</div>

			<Button raised onClick={handleCopy}>Copiar!</Button>
		</article>
	)
}

export default Item
