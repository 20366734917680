import React          from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom'

import Plans          from './Pages/Plans'
import Portfolio      from './Pages/Portfolio'
import Templates      from './Pages/Templates'
import PlanCodeAccess from './Pages/PlanCodeAccess'
import Feedback       from './Pages/Feedback/index'
import FeedbackDetail from './Pages/Feedback/Detail'



export default function RouterApp (props) {
	return (
		<Router>
			<Switch>
				<Route path="/planes" component={Plans} />
				<Route path="/plantillas" component={Templates} />
				<Route path="/codigo-acceso" component={PlanCodeAccess} />

				<Route path="/feedback/:id" component={FeedbackDetail} />
				<Route path="/feedback" component={Feedback} />

				
				<Route path="/" component={Portfolio} />
			</Switch>
		</Router>
	)
}

