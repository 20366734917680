import {
	chainCommands,
	deleteSelection,
	selectNodeForward
} from 'prosemirror-commands'

import { Selection, NodeSelection } from "prosemirror-state"


export default chainCommands(deleteSelection, joinForward, selectNodeForward)

function joinForward (state, dispatch, view) {
	let { $cursor } = state.selection

	if (!$cursor || (view ? !view.endOfTextblock("forward", state)
		: $cursor.parentOffset < $cursor.parent.content.size))
		return false

	let $cut = findCutAfter($cursor)

	// If there is no node after this, there's nothing to do
	if (!$cut) return false

	let after = $cut.nodeAfter

	// if (deleteBarrier(state, $cut, dispatch))  return true

	// If the node above has no content and the node below is
	// selectable, delete the node above and select the one below.
	if ($cursor.parent.content.size === 0
		&& (textblockAt(after, "start") || NodeSelection.isSelectable(after))
	) {

		if (dispatch) {
			let tr = state.tr.deleteRange($cursor.before(), $cursor.after())
	
			console.log("Estoy en cursor if")

			tr.setSelection(textblockAt(after, "start")
				? Selection.findFrom(tr.doc.resolve(tr.mapping.map($cut.pos)), 1)
				: NodeSelection.create(tr.doc, tr.mapping.map($cut.pos)))

			dispatch(tr.scrollIntoView())
		}
		return true
	}

	// If the next node is an atom, delete it
	if (after.isAtom && $cut.depth === $cursor.depth - 1) {
		if (dispatch) dispatch(state.tr.delete($cut.pos, $cut.pos + after.nodeSize).scrollIntoView())
		return true
	}

	return false
}


function findCutAfter($pos) {
	if (!$pos.parent.type.spec.isolating) for (let i = $pos.depth - 1; i >= 0; i--) {
		let parent = $pos.node(i)
		if ($pos.index(i) + 1 < parent.childCount) return $pos.doc.resolve($pos.after(i + 1))
		if (parent.type.spec.isolating) break
	}

	return null
}

function textblockAt(node, side) {
	for (; node; node = (side === "start" ? node.firstChild : node.lastChild))
		if (node.isTextblock) return true
	return false
}
